<script setup lang="ts">
import InlineImage from '~/components/InlineImage.vue'

defineProps({
  blocks: {
    type: Array as any,
    required: true,
  },
})

const serializers = {
  types: {
    image: InlineImage,
  },
}
</script>

<template>
  <div class="sanity-content break-word">
    <SanityContent
      :blocks="blocks"
      :serializers="serializers"
    />
  </div>
</template>

<style>
</style>
