<script setup lang="ts">
defineProps({
  asset: {
    type: Object as any,
    required: true,
  },
})

const { urlFor, dims } = useSanityImage()
</script>

<template>
  <nuxt-img
    v-if="asset && asset._ref"
    class="cover mb-4 h-auto w-full"
    :src="urlFor(asset._ref).url()"
    quality="80"
    :width="dims(asset._ref).width"
    :height="dims(asset._ref).height"
    sizes="sm:100vw md:80vw lg:70vw xl:65vw"
    :alt="asset.alt || ''"
  />
</template>
